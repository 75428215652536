import styled from '@emotion/styled';
import BackgroundImage from 'gatsby-background-image';

export const Wrapper = styled.div`
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin: 10px 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column;
  }
`;

export const CardTitle = styled.h3`  
  height: inherit;
  vertical-align: bottom;  
  margin: 0;
  display: table-cell;
  color: ${props => props.theme.colors.white};
  text-shadow: ${props => props.theme.shadow.text};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: ${props => props.theme.cardsTitleFontSize.m};
    line-height: ${props => props.theme.cardsTitleLineHeight.m};
  }
`;

export const CardTitleRight = styled.h2`
  color: ${props => props.theme.colors.white};
  text-shadow: ${props => props.theme.shadow.text};
`;

export const GreetingCardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GreetingCard = styled.div`
  width: 100%;
  height: auto;
  margin-left: 15px;
  margin-right: 15px;

  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: 0px 0px 15px rgba(0, 22, 79, 0.3);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    justify-content: space-between;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  h3 {
    text-align: center;
  }
`;

export const GreetingCardInner = styled.div`
  display: grid;
`;

export const GreetingCardTextArea = styled.div`
  display: grid;
  grid-area: 1/1;
  position: relative;
  place-items: center;
`;

export const PromotionCard = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.colors.white};
  margin-top: 30px;
  border-radius: ${props => props.theme.borderRadius.default};  
  box-shadow: 0px 0px 15px rgba(0, 22, 79, 0.3);
  overflow: hidden;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
  }
`;

export const PromotionCardInner = styled.div`
  display: grid;
`;

export const PromotionCardTextArea = styled.div `
  display: grid;
  grid-area: 1/1;
  position: relative;
  justify-content: left;
  vertical-align: bottom;
  align-content: center;
`;

export const PromotionCardTitle= styled.h3`  
  max-width: 350px;
  padding-left: 30px;
  color: ${props => props.theme.colors.white};
  text-shadow: ${props => props.theme.shadow.text};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    max-width: 250px;
    font-size: ${props => props.theme.cardsTitleFontSize.m};
    line-height: ${props => props.theme.cardsTitleLineHeight.m};
  }
`;

export const LeftContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 30px;  

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-right: 0;
    margin-bottom: 30px;
    width: 100%;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: auto;
  max-width: 555px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: none;
  }
`;

export const CreateOwnGreetingCard = styled.div`
  display: flex;
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: 0px 0px 15px rgba(0, 22, 79, 0.3);
  overflow: hidden;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
  }
`;

export const CreateOwnGreetingCardTextArea = styled.div`
  display: grid;
  grid-area: 1/1;
  position: relative;
  place-items: center;
`;

export const CreateOwnGreetingCardInner = styled.div`
  display: grid;
`;

export const AnimatedGifInner = styled.img`
  grid-area: 1/1;
  width: 100%;
  height: 100%;
`;