import styled from '@emotion/styled';

export const Wrapper = styled.div`    
  width: 100%;
  height: auto;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(101,151,199,1) 25%, rgba(101,151,199,1) 75%, rgba(255,255,255,1) 100%);
@media all and (max-width: ${props => props.theme.breakpoints.l}) {
  }

  @media all and (max-width: ${props => props.theme.breakpoints.s}) {
  }

  @media all and (max-width: ${props => props.theme.breakpoints.xs}) {
  }
`;

export const Message = styled.div`    
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 1.125rem;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  color: #fff;
`;