import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import {
    Wrapper,
    Message,
  } from './styles';


const MessageBanner = ({fluid}) => {
    return (
    <Wrapper>
        <Message>{fluid.field_display_title}</Message>
     </Wrapper>
    )
};

export default MessageBanner;