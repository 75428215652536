import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { queryHelper } from 'helpers';
import { IconButton } from 'components';

import {
  Wrapper,
  CardTitle,
  CardTitleRight,
  CreateOwnGreetingCard,
  GreetingCard,
  GreetingCardInner,
  GreetingCardTextArea,
  GreetingCardsWrapper,
  LeftContent,
  PromoTionCard,
} from './styles';

import BuyIcon from '/static/icons/nav/cart_blue.svg';

const PromoTop2 = props => {
  const {
    promo2FirstSection,
    promo2SecondSection,
    promo2ThirdSection,
    promo2FourthSection,
  } = useStaticQuery(graphql`
    query MyPromoTop2Query {
      promo2FirstSection: nodeSection(
        title: { eq: "homePromo2FirstSection" }
      ) {
        drupal_id
        title
        field_display_title
        field_url
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                  layout: CONSTRAINED
                )
              }
            }
          }
          field_button_image {
            localFile {
              publicURL
            }
          }
        }
        field_button_title
        field_button_url
      }
      promo2SecondSection: nodeSection(
        title: { eq: "homePromo2SecondSection" }
      ) {
        drupal_id
        title
        field_display_title
        field_url
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                  layout: CONSTRAINED
                )
              }
            }
          }
          field_button_image {
            localFile {
              publicURL
            }
          }
        }
        field_button_title
        field_button_url
      }
      promo2ThirdSection: nodeSection(
        title: { eq: "homePromo2ThirdSection" }
      ) {
        drupal_id
        title
        field_display_title
        field_url
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                  layout: CONSTRAINED
                )
              }
            }
          }
          field_button_image {
            localFile {
              publicURL
            }
          }
        }
        field_button_title
        field_button_url
      }
      promo2FourthSection: nodeSection(
        title: { eq: "homePromo2FourthSection" }
      ) {
        drupal_id
        title
        field_display_title
        field_url
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                  layout: CONSTRAINED
                )
              }
            }
          }
          field_button_image {
            localFile {
              publicURL
            }
          }
        }
        field_button_title
        field_button_url
      }
    }
  `);

  return (
    <Wrapper>      
       <GreetingCardsWrapper>
        <GreetingCard>
          <GreetingCardInner>
            <GatsbyImage 
                style={{gridArea: "1/1"}}
                layout="fullWidth"
                aspectRatio={3 / 1}
                image={getImage(queryHelper.getGatsbyImageData(promo2FirstSection))} alt=""
              />
              <GreetingCardTextArea>
                <CardTitle>{promo2FirstSection.field_display_title}</CardTitle>
                  <Link
                    to={queryHelper.getSafeLink(
                      promo2FirstSection.field_button_url
                    )}
                  >
                    <IconButton title={promo2FirstSection.field_button_title}>
                      <BuyIcon />
                    </IconButton>
                </Link>
              </GreetingCardTextArea>    
            </GreetingCardInner>
        </GreetingCard>
        <GreetingCard>
          <GreetingCardInner>
            <GatsbyImage 
                style={{gridArea: "1/1"}}
                layout="fullWidth"
                aspectRatio={3 / 1}
                image={getImage(queryHelper.getGatsbyImageData(promo2SecondSection))} alt=""
              />
              <GreetingCardTextArea>
                <CardTitle>{promo2SecondSection.field_display_title}</CardTitle>
                  <Link
                    to={queryHelper.getSafeLink(
                      promo2SecondSection.field_button_url
                    )}
                  >
                    <IconButton title={promo2SecondSection.field_button_title}>
                      <BuyIcon />
                    </IconButton>
                </Link>
              </GreetingCardTextArea>    
            </GreetingCardInner>
        </GreetingCard>
        <GreetingCard>
          <GreetingCardInner>
            <GatsbyImage 
                style={{gridArea: "1/1"}}
                layout="fullWidth"
                aspectRatio={3 / 1}
                image={getImage(queryHelper.getGatsbyImageData(promo2ThirdSection))} alt=""
              />
              <GreetingCardTextArea>
                <CardTitle>{promo2ThirdSection.field_display_title}</CardTitle>
                  <Link
                    to={queryHelper.getSafeLink(
                      promo2ThirdSection.field_button_url
                    )}
                  >
                    <IconButton title={promo2ThirdSection.field_button_title}>
                      <BuyIcon />
                    </IconButton>
                </Link>
              </GreetingCardTextArea>    
            </GreetingCardInner>
        </GreetingCard>
        <GreetingCard>
          <GreetingCardInner>
            <GatsbyImage 
                style={{gridArea: "1/1"}}
                layout="fullWidth"
                aspectRatio={3 / 1}
                image={getImage(queryHelper.getGatsbyImageData(promo2FourthSection))} alt=""
              />
              <GreetingCardTextArea>
                <CardTitle>{promo2FourthSection.field_display_title}</CardTitle>
                  <Link
                    to={queryHelper.getSafeLink(
                      promo2FourthSection.field_button_url
                    )}
                  >
                    <IconButton title={promo2FourthSection.field_button_title}>
                      <BuyIcon />
                    </IconButton>
                </Link>
              </GreetingCardTextArea>    
            </GreetingCardInner>
        </GreetingCard>
        </GreetingCardsWrapper>
    </Wrapper>
  );
};

export default PromoTop2;
