import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Search from 'components/sections/Search';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { queryHelper } from 'helpers';
import { IconButton } from 'components';

import {
  Wrapper,
  SearchContainer,
  SideImage,
  Title,
  Description,
  SearchSectionInner,
  SearchSectionInnerObjects,
} from './styles';

const SearchBanner = ({fluid}) => {
  const {
    homeSearchSection
  } = useStaticQuery(graphql`
    query MySearchBannerSection {
      homeSearchSection: nodeSection(
        title: { eq: "homeSearchSection" }
      ) {
        drupal_id
        title
        field_display_title
        field_url
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                  layout: CONSTRAINED
                )
              }
            }
          }
          field_button_image {
            localFile {
              publicURL
            }
          }
        }
        field_button_title
        field_button_url
      }
    }
  `);

  return (
    <Wrapper>
      <SearchContainer>   
        <SearchSectionInner>
          <GatsbyImage 
                    style={{gridArea: "1/1"}}
                    layout="fullWidth"
                    aspectRatio={3 / 1}
                    image={getImage(queryHelper.getGatsbyImageData(homeSearchSection))} alt=""
                  />
            <SearchSectionInnerObjects>
              <div className="search">
                <Search />
              </div>        
              <Description><i>{homeSearchSection.field_display_title}</i></Description>
            </SearchSectionInnerObjects>
        </SearchSectionInner> 
      </SearchContainer>
    </Wrapper>
  );
};

export default SearchBanner;