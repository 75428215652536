import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from '@emotion/styled';
import { InstantSearch, connectHits, Configure, CurrentRefinements } from 'react-instantsearch-dom';
import algoliaInfo from '/config/algolia.js';
import { v4 } from 'uuid';

const Wrapper = styled.div`
  .img-wrapper {
    float: left;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 155px;
    height: 215px;
    position: relative;
    transition: all 0.4s ease-out 0s;
    box-shadow: rgb(0 0 0 / 50%) 0px 7px 10px;
  }
  .img-wrapper:nth-of-type(1) {
    transform: rotate(-10deg);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 0;
    -webkit-transform: translate3d(0,0,0) rotate(-10deg);
    visibility:visible;
    backface-visibility: hidden;
}
  .img-wrapper:nth-of-type(2) {
      margin-left: -45px;
      margin-top: 5px;
      transform: rotate(10deg);
      -webkit-backface-visibility: hidden;
      -webkit-perspective: 0;
      -webkit-transform: translate3d(0,0,0) rotate(10deg);
      visibility:visible;
      backface-visibility: hidden;
  }
  @media all and (max-width: ${props => props.theme.breakpoints.l}) {
    .img-wrapper {
      display: none;
    }
  }
  @media all and (max-width: ${props => props.theme.breakpoints.s}) {
    ..img-wrapper {
      display: none;
    }
  }
  @media all and (max-width: ${props => props.theme.breakpoints.xs}) {
    .img-wrapper {
      display: none;
    }
  }
`;

const homePageCardHits = ({ hits }) => {
  //console.log("hits",JSON.stringify(hits));
    return (
        <Wrapper>
        {hits
          .map(hit => (
              <div className="img-wrapper" key={v4()}>
                  <GatsbyImage image={getImage(hit.relationships.field_image.localFile.childImageSharp.gatsbyImageData)} alt={hit.title} className="img" key={v4()}/>                  
              </div>
          ))}
        </Wrapper>
    );
  };

const CustomHits = connectHits(homePageCardHits);

const CardImages = (props) => {
  //const { category } = props;
  const { searchClient } = algoliaInfo;
  var offsetNo = Math.floor(Math.random() * 10);
  
  const {
    promoLeftTopSection
  } = useStaticQuery(graphql`
    query MyCategoryQuery {
      promoLeftTopSection: nodeSection(title: {eq: "homePromoTopRightSection"}) {
        id
        relationships {
          field_category {
            name
          }
        }
      }
    }
  `);
  
  const categoryQuery = JSON.parse(JSON.stringify(promoLeftTopSection));
  const category = categoryQuery.relationships.field_category[0].name;

  function getConfigureProps() {
    const defaultProps = {
      hitsPerPage: 2,
      offset: offsetNo,
      length: 2
    }
  
    return {
      ...defaultProps,
    };
  }

  return (
      <Wrapper>
          <InstantSearch indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME_INVENTORY} searchClient={searchClient}>
            <Configure {...getConfigureProps()} filters={category ? `relationships.field_category.name:"${category.replace("'", "\'")}"`: ""}/>
            <CurrentRefinements
              transformItems={items =>
                items.filter(item => item.currentRefinement !== 'Rude')
              }
            />
            <CustomHits />
          </InstantSearch>
      </Wrapper>
  )
}

export default CardImages;