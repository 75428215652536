import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { MainBanner, PromoTop, PromoTop2, TopBanner, BottomBanner, BestSellers, SellingPoints, SearchBanner, MessageBanner } from 'components/pages/home';
import { SEO } from '../layouts/SEO';
import { Layout, Container } from 'layouts';
import LoadingGif from '/static/gifs/loading.gif';
import './style.css';

const HomePage = ({ data: {mainBanner,topBanner,bottomBanner,messageBanner} }) => {
  /*
  const url = mainBanner.field_url;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (!code) {
      setIsLoading(false);
    }
  }, []);


  if (isLoading) {
    return <LoadingComponent />;
  }
  */
 
  return (
    <Layout title="Home">
      <Container>
        {/*<MainBanner fluid={mainBanner} link={url} />*/}
        {/*<BestSellers />*/}
        <MessageBanner fluid={messageBanner} />
        <TopBanner fluid={topBanner} />
        <SearchBanner />
        <PromoTop />
        <PromoTop2 />
        <SellingPoints />                
      </Container>
    </Layout>
  );
};

// Removed bottom banner - <BottomBanner fluid={bottomBanner} />

export const query = graphql`
  query MyHomePageQuery {
    messageBanner: nodeBanner(title: { eq: "homeMessageBanner" }) {
      drupal_id
      title
      field_display_title
      field_description
      field_url
      relationships {
        field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [WEBP]
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    mainBanner: nodeBanner(title: { eq: "homeMainBanner" }) {
      drupal_id
      title
      field_display_title
      field_description
      field_url
      relationships {
        field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [WEBP]
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    topBanner: nodeBanner(title: { eq: "homeTopBanner" }) {
      drupal_id
      title
      field_display_title
      field_description
      relationships {
        field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [WEBP]
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    bottomBanner: nodeBanner(title: { eq: "homeBottomBanner" }) {
      drupal_id
      title
      field_display_title
      field_description
      relationships {
        field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [WEBP]
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`;
export default HomePage;

export const Head = () => (
  <SEO />
)

/*const LoadingComponent = () => {
  return (
    <Section className="loading-wrapper">
      <img className="loading-gif large" src={LoadingGif} alt="image loading" />
      <p>Just a moment ...</p>
    </Section>
  );
};*/

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
`;