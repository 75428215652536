import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';

import { queryHelper } from 'helpers';
import {
  Wrapper,
  Card,
  CardWrapper,
  RoundedIconButton,
  CardTitle,
} from './styles';

import ArrowIcon from '/static/icons/arrows/arrow.svg';

const SellingPoints = () => {
  const { sellingPoints } = useStaticQuery(graphql`
    query MySellingPointsQuery {
      sellingPoints: nodeHomeUspButtonGroup {
        drupal_id
        title
        relationships {
          field_image1 {
            localFile {
              publicURL
            }
          }
          field_image2 {
            localFile {
              publicURL
            }
          }
          field_image3 {
            localFile {
              publicURL
            }
          }
          field_image4 {
            localFile {
              publicURL
            }
          }
        }
        field_display_title1
        field_url1
        field_display_title2
        field_url2
        field_display_title3
        field_url3
        field_display_title4
        field_url4
      }
    }
  `);

  let sellingItems = []; // Initialize selling items
  [...Array(4)].map((_, i) => {
    // Loop 4 times
    return sellingItems.push({
      // Add an object with the below fields
      title: sellingPoints[`field_display_title${i + 1}`],
      icon:
        sellingPoints.relationships[`field_image${i + 1}`]['localFile'][
          'publicURL'
        ],
      url: sellingPoints[`field_url${i + 1}`],
    });
  });

  return (
    <Wrapper>
      {sellingItems.map(item => {
        return (
          <Card
            key={queryHelper.getUniqueKey(item)}
            onClick={() => navigate(queryHelper.getSafeLink(item.url))}
          >
            <CardWrapper>
              <img src={item.icon} />
              <CardTitle>
                <b>{item.title}</b>
              </CardTitle>
            </CardWrapper>
            <RoundedIconButton>
              <ArrowIcon />
            </RoundedIconButton>
          </Card>
        );
      })}
    </Wrapper>
  );
};

export default SellingPoints;