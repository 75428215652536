import styled from '@emotion/styled';
import BackgroundImage from 'gatsby-background-image';

export const Wrapper = styled.div`
  display: flex;
  margin: 1rem 0;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
   
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column;
  }
`;

export const CardTitle = styled.h3`  
  height: inherit;
  vertical-align: bottom;  
  margin: 0;
  display: table-cell;
  color: ${props => props.theme.colors.white};
  text-shadow: ${props => props.theme.shadow.text};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: ${props => props.theme.cardsTitleFontSize.m};
    line-height: ${props => props.theme.cardsTitleLineHeight.m};
  }
`;

export const GreetingCardsWrapper = styled.div`
  width: 100%;
  display: flex;  
  justify-content: space-between;
  
  @media (max-width: ${props => props.theme.breakpoints.s}) {    
    flex-wrap: wrap;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    
  }

`;

export const GreetingCard = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  float: left;

  border-radius: ${props => props.theme.borderRadius.default};  
  box-shadow: 0px 0px 15px rgba(0, 22, 79, 0.3);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;  

  @media (max-width: ${props => props.theme.breakpoints.s}) {          
    margin: 8px auto;
    max-width: 150px;
    justify-content: space-evenly;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {        
    margin: 8px auto;
    max-width: 155px;
    justify-content: space-evenly;
  }

  h3 {
    text-align: center;
  }
`;

export const GreetingCardInner = styled.div`
  display: grid;
`;

export const GreetingCardTextArea = styled.div`
  display: grid;
  grid-area: 1/1;
  position: relative;
  place-items: center;
`;
