import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import promoRightSectionGIF from '../../../../../static/gifs/promocard.gif';
import { queryHelper } from 'helpers';
import { IconButton } from 'components';

import {
  Wrapper,
  CardTitle,
  CardTitleRight,
  CreateOwnGreetingCard,
  CreateOwnGreetingCardInner,
  CreateOwnGreetingCardTextArea,
  GreetingCard,
  GreetingCardInner,
  GreetingCardTextArea,
  GreetingCardsWrapper,
  LeftContent,
  PromotionCard,
  PromotionCardInner,
  PromotionCardTextArea,
  PromotionCardTitle,
  RightContent,
  AnimatedGifInner,
} from './styles';

import BuyIcon from '/static/icons/nav/cart_blue.svg';

const PromoTop = props => {
  const {
    promoLeftTopSection,
    promoRightTopSection,
    promoLeftBottomSection,
    promoRightSection,
  } = useStaticQuery(graphql`
    query MyPromoTopQuery {
      promoLeftTopSection: nodeSection(
        title: { eq: "homePromoTopLeftSection" }
      ) {
        drupal_id
        title
        field_display_title
        field_url
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                  layout: CONSTRAINED
                )
              }
            }
          }
          field_button_image {
            localFile {
              publicURL
            }
          }
        }
        field_button_title
        field_button_url
      }
      promoRightTopSection: nodeSection(
        title: { eq: "homePromoTopRightSection" }
      ) {
        drupal_id
        title
        field_display_title
        field_url
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                  layout: CONSTRAINED
                )
              }
            }
          }
          field_button_image {
            localFile {
              publicURL
            }
          }
        }
        field_button_title
        field_button_url
      }
      promoLeftBottomSection: nodeSection(
        title: { eq: "homePromoBottomLeftSection" }
      ) {
        drupal_id
        title
        field_display_title
        field_url
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                  layout: CONSTRAINED
                )
              }
            }
          }
          field_button_image {
            localFile {
              publicURL
            }
          }
        }
        field_button_title
        field_button_url
      }
      promoRightSection: nodeSection(title: { eq: "homePromoRightSection" }) {
        drupal_id
        title
        field_display_title
        field_url
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                  layout: CONSTRAINED
                )
              }
            }
          }
          field_button_image {
            localFile {
              publicURL
            }
          }
        }
        field_button_title
        field_button_url
      }
    }
  `);

  return (
    <Wrapper>
      <LeftContent>
        <GreetingCardsWrapper>
          <GreetingCard>
            <GreetingCardInner>
              <GatsbyImage 
                style={{gridArea: "1/1"}}
                layout="fullWidth"
                aspectRatio={3 / 1}
                image={getImage(queryHelper.getGatsbyImageData(promoLeftTopSection))} alt=""
              />
              <GreetingCardTextArea>
                <CardTitle>{promoLeftTopSection.field_display_title}</CardTitle>
                <Link
                  to={queryHelper.getSafeLink(
                    promoLeftTopSection.field_button_url
                  )}
                >
                  <IconButton title={promoLeftTopSection.field_button_title}>
                    <BuyIcon />
                  </IconButton>
                </Link>
              </GreetingCardTextArea>
            </GreetingCardInner>
          </GreetingCard>
          <GreetingCard>
            <GreetingCardInner>
                <GatsbyImage 
                  style={{gridArea: "1/1"}}
                  layout="fullWidth"
                  aspectRatio={3 / 1}
                  image={getImage(queryHelper.getGatsbyImageData(promoRightTopSection))} alt=""
                />
                <GreetingCardTextArea>
                  <CardTitle>{promoRightTopSection.field_display_title}</CardTitle>
                  <Link
                    to={queryHelper.getSafeLink(
                      promoRightTopSection.field_button_url
                    )}
                  >
                    <IconButton title={promoRightTopSection.field_button_title}>
                      <BuyIcon />
                    </IconButton>
                  </Link>
                </GreetingCardTextArea>
              </GreetingCardInner>
          </GreetingCard>
        </GreetingCardsWrapper>
        <PromotionCard>
          <PromotionCardInner>
              <GatsbyImage 
                style={{gridArea: "1/1"}}
                layout="fullWidth"
                aspectRatio={3 / 1}
                image={getImage(queryHelper.getGatsbyImageData(promoLeftBottomSection))} alt=""
              />
              <GreetingCardTextArea>
                <CardTitle>{promoLeftBottomSection.field_display_title}</CardTitle>
                  <Link
                    to={queryHelper.getSafeLink(
                      promoLeftBottomSection.field_button_url
                    )}
                  >
                    <IconButton title={promoLeftBottomSection.field_button_title}>
                      <BuyIcon />
                    </IconButton>
                  </Link>
                </GreetingCardTextArea>
            </PromotionCardInner>
        </PromotionCard>
      </LeftContent>
      <RightContent>
        <CreateOwnGreetingCard>
          <CreateOwnGreetingCardInner>  
            <GatsbyImage 
              style={{gridArea: "1/1"}}
              layout="fullWidth"
              aspectRatio={3 / 1}
              image={getImage(queryHelper.getGatsbyImageData(promoRightSection))} alt=""
            />
            {/* -- Animated gif refers to /static/gifs/ path
              <Link to="/freecards">
              <AnimatedGifInner src={promoRightSectionGIF}/>  
              </Link> 
            */}
             <GreetingCardTextArea>
                <CardTitle>{promoRightSection.field_display_title}</CardTitle>
                  <Link
                    to={queryHelper.getSafeLink(
                      promoRightSection.field_button_url
                    )}
                  >
                    <IconButton title={promoRightSection.field_button_title}>
                      <BuyIcon />
                    </IconButton>
                  </Link>
                </GreetingCardTextArea>
          </CreateOwnGreetingCardInner>  
        </CreateOwnGreetingCard>
      </RightContent>
    </Wrapper>
  );
};

export default PromoTop;