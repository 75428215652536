import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { queryHelper } from 'helpers';
import { IconButton } from 'components';
import BuyIcon from '/static/icons/nav/cart_blue.svg';
import CardImages from './CardImages';
import Search from 'components/sections/Search';
import Flags from './Flags';

import {
  Wrapper,
  TopBannerContainer,
  TopBannerSectionInner,
  TopBannerSectionInnerObjects,
  FlagsContainer,
  SideImage,
  Title,
  Description,
} from './styles';

const TopBanner = ({fluid}) => {
  const {
    homeTopSection
  } = useStaticQuery(graphql`
    query MySearchTopBannerSection {
      homeTopSection: nodeSection(
        title: { eq: "homeTopSection" }
      ) {
        drupal_id
        title
        field_display_title
        field_description
        field_url
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                  layout: CONSTRAINED
                )
              }
            }
          }
          field_button_image {
            localFile {
              publicURL
            }
          }
        }
        field_button_title
        field_button_url
      }
    }
  `);

  return (
    <Wrapper>
      <TopBannerContainer>   
        <TopBannerSectionInner>
            <GatsbyImage 
              style={{gridArea: "1/1"}}
              layout="fullWidth"
              aspectRatio={3 / 1}
              image={getImage(queryHelper.getGatsbyImageData(homeTopSection))} alt=""
            />
            <TopBannerSectionInnerObjects>   
              <Title>{homeTopSection.field_display_title}</Title>
              <Description>{homeTopSection.field_description}</Description>
              <Link
                to={queryHelper.getSafeLink(
                  homeTopSection.field_button_url
                )}
              >
                <IconButton title={homeTopSection.field_button_title}>
                  <BuyIcon />
                </IconButton>
              </Link>
              {/*<FlagsContainer>
                <Description> 
                  <i>We delivery to Singapore, Malaysia, Hong Kong, Philippines, Indonesia, Thailand + World Wide Delivery</i>
                  <Flags /> 
                </Description>
                </FlagsContainer> */}
              {/*<SideImage>
               <CardImages />
              </SideImage>*/}
          </TopBannerSectionInnerObjects>
        </TopBannerSectionInner> 
      </TopBannerContainer>
    </Wrapper>
  );
};

export default TopBanner;