import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0;

  @media all and (max-width: ${props => props.theme.breakpoints.l}) {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 5px 0;
  }

  @media all and (max-width: ${props => props.theme.breakpoints.m}) {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 5px 0;
  }

  @media all and (max-width: ${props => props.theme.breakpoints.xs}) {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 5px 0;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
  border-radius: ${props => props.theme.borderRadius.round};
  border: ${props => props.theme.borders.card};
  box-shadow: ${props => props.theme.shadow.card};
  background: ${props => props.theme.colors.white};
  position: relative;

  width: 100%;
  height: 165px;
  cursor: pointer;

  img {
    margin-bottom: 0;
  }

  &:hover {
    button {
      background: ${props => props.theme.colors.lightBlue};
    }
  }
`;

export const CardWrapper = styled.div`
  width: 100%;
  text-align: -webkit-center;
  padding: 2rem;

  @media all and (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 20px 10px;
  }

  p {
    margin-bottom: 0;
  }
`;

export const RoundedIconButton = styled.button`
  cursor: pointer;
  font-weight: ${props => props.theme.fontWeights.button};
  height: 30px;
  width: 70px;
  position: absolute;
  right: -1px;
  top: -1px;
  border-radius: 0px 10px;
  background: ${props => props.theme.colors.gray};
  box-sizing: border-box;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  @media all and (max-width: ${props => props.theme.breakpoints.m}) {
    width: 50px;
  }

  svg {
    color: ${props => props.theme.colors.black};
    width: 30px;

    @media all and (max-width: ${props => props.theme.breakpoints.m}) {
      width: 15px;
    }
  }
`;

export const CardTitle = styled.p`
  @media all and (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: ${props => props.theme.cardsTitleFontSize.xs};
    line-height: ${props => props.theme.cardsTitleLineHeight.m};
  }
`;
