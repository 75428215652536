import { graphql, useStaticQuery } from "gatsby"

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query SEO {
        site {
            buildTime(formatString: "YYYY-MM-DD")
            siteMetadata {
                abstract
                title
                description                             
                image
                shortName
                siteUrl
                siteLanguage
                twitterUsername
            }
        }
    }
  `)

  return data.site.siteMetadata
}