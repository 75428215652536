import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  margin: 1rem 0;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin: 10px 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  height: auto;
  margin-left: 15px;
  margin-right: 15px;

  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: 0px 0px 15px rgba(0, 22, 79, 0.3);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    justify-content: space-between;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  h3 {
    text-align: center;
  }
`;

export const SearchSectionInner = styled.div`
  display: grid;
`;

export const SearchSectionInnerObjects = styled.div`
  display: grid;
  grid-area: 1/1;
  position: relative;
  place-items: center;
  padding: 20px;
`;

export const SideImage = styled.div`
  width: 25%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
    max-width: 230px;
  }
`;

export const Title = styled.h1`
  font-family: Montserrat,sans-serif;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-size: 31px;
  line-height: 38px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: 10px;
    font-size: ${props => props.theme.titleFontSize.m};
  }
`;

export const Description = styled.p`  
  padding-left: 30px;
  color: ${props => props.theme.colors.white};
  text-shadow: ${props => props.theme.shadow.text};
  font-size: ${props => props.theme.titleFontSize.m};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  } 
`;

export const Flag = styled.img`
  width: 45px;
  height: 45px;
  box-shadow: ${props => props.theme.shadow.card};
  filter: drop-shadow(0px 35px 60px rgba(0, 22, 79, 0.1));

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }  
`;

